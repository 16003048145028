import BasePlugin from '../BasePlugin'

export default class CalculatePrerequisites extends BasePlugin {
  async execute () {
    // commit for build
    let recordId = this.context.getParentContext().getModel().id
    let purchases = this.context.getDashboardComponents()['component_4a5f8c0b-b94c-4409-a144-867942bb5975'][0]['$refs']['table'].getData()

    let prerequisites = this.context.getDashboardComponents()['component_5b896d1a-c28f-49b7-873a-7cfafdf577bc'][0]['$refs']['table'].getData()
    let attributes = []
    let modelObject = this.context.getDashboardComponents()['component_9bef4704-e373-438b-8f11-ac87eb5fbe89'][0].getModel()
    Object.keys(modelObject).map(function (key) {
      attributes[key] = modelObject[key]
      return true
    })
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/InvestmentExpensesBudget/CalculatePrerequisitesCommand`,
      { 'objectId': recordId, 'purchases': purchases, 'attributes': Object.assign({}, attributes), 'prerequisites': prerequisites },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      this.context.getParentContext().getDashboardComponents()['component_9671665d-6dd9-43b2-8e25-0bac61f6e094'][0].$refs.registry.addRecord(response.data.investment_budget_id)
      this.context.$msgbox.close()
      this.context.$msgbox({
        type: 'info',
        message: 'Основное средство добавлено'
      })
    })
  }
}
